@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fourel/ui/src/shared-globals.css";

.fourel_main_background_picture {
  background-image: url("./images/background-light.jpg");

  @supports (background-image: url("./images/background-light.webp")) {
    background-image: url("./images/background-light.webp");
  }
}

html.dark .fourel_main_background_picture {
  background-image: url("./images/background-dark.jpg");

  @supports (background-image: url("./images/background-dark.webp")) {
    background-image: url("./images/background-dark.webp");
  }
}
